<!--
 * @Author: jiang
 * @Date: 2021-07-02 09:55:23
 * @Description:
-->
<template>
  <div>
    <info-item
      label="手机号"
      :value="data.phone"
    ></info-item>
    <info-item
      label="用户姓名"
      :value="data.name"
    ></info-item>
    <info-item
      label="所在部门"
      :value="data.department_name"
    ></info-item>
    <info-item
      label="所在部门"
      :value="data.duties"
    ></info-item>
  </div>
</template>

<script>
import InfoItem from '@/components/base/info/info-item.vue'
export default {
  components: {
    InfoItem,
  },
  props: {
    data: {},
  },
}
</script>
